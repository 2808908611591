var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('g-form',{on:{"submit":_vm.save}},[_c('b-row',{staticClass:"p-0 mb-1"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{staticClass:"mr-1 btn-sm",attrs:{"variant":_vm.file.name ? 'success' : 'relief-primary',"data-action-type":"new"},on:{"click":function (v) {
              _vm.openModal();
            }}},[_vm._v(" "+_vm._s(_vm.file.name ? _vm.$t("hasAttachMents") : _vm.$t("attachFile"))+" "),_c('feather-icon',{staticClass:"ml-25",attrs:{"icon":"UploadIcon","size":"15"}})],1),(_vm.selectedItem.attachmentUrl)?_c('a',{staticClass:"mr-1 btn-sm btn btn-success",attrs:{"href":_vm.selectedItem.attachmentUrl},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t("attachedFile"))+" "),_c('feather-icon',{staticClass:"ml-25",attrs:{"icon":"DownloadIcon","size":"15"}})],1):_vm._e()],1),(!_vm.selectedItem.postedVoucherId)?_c('b-col',{attrs:{"cols":"4"}}):_vm._e(),(_vm.selectedItem.postedVoucherId)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{staticClass:"mr-1 btn-sm",attrs:{"variant":'dark',"data-action-type":"new"},on:{"click":function (v) {
              this$1.$router.push({
                name: 'vouchers-edit',
                params: { id: _vm.selectedItem.postedVoucherId },
              });
            }}},[_vm._v(" "+_vm._s(((_vm.$t('voucherNum')) + " " + (_vm.selectedItem.voucherCode)))+" ")])],1):_vm._e()],1),_c('hr',{staticClass:"border-bottom-primary border-darken-2"}),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{attrs:{"id":"code","type":"number","label-text":"code","name":"code","readonly":"","value":_vm.selectedItem.code},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "code", $event)}}})],1),(!_vm.currentBranch.setDefaultDate)?_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{attrs:{"value":_vm.getDate(_vm.selectedItem.transactionDate),"label-text":"date","disabled":"","name":"transactionDate"}})],1):_c('b-col',{attrs:{"md":"4"}},[_c('g-picker',{attrs:{"value":_vm.selectedItem.transactionDate,"label-text":"date","name":"transactionDate"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "transactionDate", $event)}}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"label-text":"transactionTime","name":"transactionTime","readonly":"","value":_vm.selectedItem.transactionTime},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "transactionTime", $event)}}})],1)],1),(
          _vm.pathName === 'expense-transactions-edit' ||
            _vm.pathName === 'expense-transactions-new'
        )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.expenseId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"expense","field":"select","name":"bank1","options":_vm.expenses,"rules":"required","label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "expenseId", $event)}}})],1)],1):_vm._e(),(
          _vm.pathName === 'income-transactions-edit' ||
            _vm.pathName === 'income-transactions-new'
        )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.incomeId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"income","field":"select","name":"bank1","options":_vm.incomes,"rules":"required","label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "incomeId", $event)}}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"field":"number","rules":"required","type":"number","label-text":"value","value":_vm.selectedItem.value},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "value", $event)}}})],1)],1),(_vm.selectedItem.isTaxable)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"field":"number","label-text":"originalValue","type":"number","disabled":"","value":_vm.selectedItem.originalValue},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "originalValue", $event)}}})],1)],1):_vm._e(),(_vm.selectedItem.isTaxable)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"field":"number","label-text":"taxValue","type":"number","disabled":"","value":_vm.selectedItem.taxValue},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "taxValue", $event)}}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticStyle:{"font-size":"14px","margin-bottom":"7px"},attrs:{"for":"isTaxable"}},[_vm._v(" "+_vm._s(_vm.$t('isTaxable'))+" ")]),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"isTaxable","inline":""},model:{value:(_vm.selectedItem.isTaxable),callback:function ($$v) {_vm.$set(_vm.selectedItem, "isTaxable", $$v)},expression:"selectedItem.isTaxable"}})],1)],1),(
          (_vm.pathName === 'expense-transactions-edit' ||
            _vm.pathName === 'expense-transactions-new') &&
            _vm.selectedItem.isTaxable
        )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"label":"arabicName","dir":_vm.isRight ? 'rtl' : 'ltr',"rules":"required","label-text":"supplier","field":"select","name":"supplierId","options":_vm.suppliers,"value":_vm.selectedItem.supplierId},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "supplierId", $event)}}})],1)],1):_vm._e(),(
          (_vm.pathName === 'expense-transactions-edit' ||
            _vm.pathName === 'expense-transactions-new') &&
            _vm.selectedItem.isTaxable
        )?_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{attrs:{"id":"supplierInvoiceNumber","label-text":"supplierInvoiceNumber","value":_vm.selectedItem.supplierInvoiceNumber,"name":"scalesCode","type":"number","rules":"required"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "supplierInvoiceNumber", $event)}}})],1):_vm._e(),(
          (_vm.pathName === 'expense-transactions-edit' ||
            _vm.pathName === 'expense-transactions-new') &&
            _vm.selectedItem.isTaxable
        )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"id":"supplierTaxNumber","name":"supplierTaxNumber","label-text":"taxNumber","disabled":"","value":_vm.ExtendedItemInfo.supplierTaxNumber},on:{"update:value":function($event){return _vm.$set(_vm.ExtendedItemInfo, "supplierTaxNumber", $event)}}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.paymentMethod,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"Payment Method","field":"select","name":"paymentMethod","options":_vm.paymentWays,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "paymentMethod", $event)}}})],1)],1),(_vm.selectedItem.paymentMethod == 'cash')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"field":"select","label":"arabicName","name":"safePayment","label-text":"safeName","options":_vm.safes,"dir":_vm.isRight ? 'rtl' : 'ltr',"value":_vm.selectedItem.safeId},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "safeId", $event)}}})],1)],1):_vm._e(),(_vm.selectedItem.paymentMethod == 'other')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.paymentMethodId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"PaymentMethods","field":"select","name":"PaymentMethods","options":_vm.payments,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "paymentMethodId", $event)},"change":function (v) {
                _vm.selectedItem.bankId = v.bankId;
              }}})],1)],1):_vm._e(),(_vm.selectedItem.paymentMethod == 'other')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.bankId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"bankName","field":"select","name":"bank1","disabled":"","options":_vm.Banks,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "bankId", $event)}}})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"pt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notes')}},[_c('b-form-textarea',{attrs:{"id":"textarea","label-text":"Notes","rows":"3","max-rows":"6"},model:{value:(_vm.selectedItem.notes),callback:function ($$v) {_vm.$set(_vm.selectedItem, "notes", $$v)},expression:"selectedItem.notes"}})],1)],1)],1)],1),_c('b-modal',{ref:"upload-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"lg","hide-footer":"","title":_vm.$t('attachFile')}},[_c('g-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('input',{staticClass:"excel-upload-input",attrs:{"type":"file","accept":".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"},on:{"change":function($event){return _vm.handleFileUpload($event)}}}),(_vm.file)?_c('b-alert',{staticClass:"mt-2 text-center",attrs:{"show":"","fade":"","variant":"success"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.$t('importSuccsefly'))+" "+_vm._s(_vm.file.name))])])]):_vm._e()],1)])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-primary"},on:{"click":_vm.closeModal}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('b-button',{attrs:{"variant":"secondary","data-action-type":"delete","disabled":!_vm.file},on:{"click":function () {
                _vm.removeAttachment();
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.$route.meta.permission),expression:"$route.meta.permission"}],staticClass:"mr-1",attrs:{"type":"submit","data-action-type":"save","variant":"relief-primary"},on:{"click":function($event){return _vm.save()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1),_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.$route.meta.permission),expression:"$route.meta.permission"}],attrs:{"disabled":!_vm.selectedItem.value,"variant":"outline-primary","data-action-type":"saveAndPrint"},on:{"click":function($event){return _vm.save('saveAndPrint')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PrinterIcon"}}),_vm._v(" "+_vm._s(_vm.$t('saveAndPrint'))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }