<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col md="4">
          <b-button
            :variant="file.name ? 'success' : 'relief-primary'"
            data-action-type="new"
            class="mr-1 btn-sm"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon
              icon="UploadIcon"
              size="15"
              class="ml-25"
            />
          </b-button>
          <a
            v-if="selectedItem.attachmentUrl"
            :href="selectedItem.attachmentUrl"
            class="mr-1 btn-sm btn btn-success"
            @click.prevent="downloadItem(selectedItem)"
          >
            {{ $t("attachedFile") }}
            <feather-icon
              icon="DownloadIcon"
              size="15"
              class="ml-25"
            />
          </a>
        </b-col>
        <b-col
          v-if="!selectedItem.postedVoucherId"
          cols="4"
        />
        <b-col
          v-if="selectedItem.postedVoucherId"
          cols="4"
        >
          <b-button
            :variant="'dark'"
            data-action-type="new"
            class="mr-1 btn-sm"
            @click="
              (v) => {
                this.$router.push({
                  name: 'vouchers-edit',
                  params: { id: selectedItem.postedVoucherId },
                });
              }
            "
          >
            {{ `${$t('voucherNum')} ${selectedItem.voucherCode}` }}
          </b-button>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-row>
        <!-- code  -->
        <b-col md="4">
          <g-field
            id="code"
            type="number"
            label-text="code"
            name="code"
            readonly
            :value.sync="selectedItem.code"
          />
        </b-col>

        <!-- transaction date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.transactionDate)"
            label-text="date"
            disabled
            name="transactionDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="transactionDate"
          />
        </b-col>

        <!-- transaction time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="transactionTime"
              name="transactionTime"
              readonly
              :value.sync="selectedItem.transactionTime"
            />
          </b-form-group>
        </b-col>

        <!-- expenses  -->
        <b-col
          v-if="
            pathName === 'expense-transactions-edit' ||
              pathName === 'expense-transactions-new'
          "
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.expenseId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="expense"
              field="select"
              name="bank1"
              :options="expenses"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- income -->
        <b-col
          v-if="
            pathName === 'income-transactions-edit' ||
              pathName === 'income-transactions-new'
          "
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.incomeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="income"
              field="select"
              name="bank1"
              :options="incomes"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              rules="required"
              type="number"
              label-text="value"
              :value.sync="selectedItem.value"
            />
          </b-form-group>
        </b-col>

        <!-- "original value  -->
        <b-col
          v-if="selectedItem.isTaxable"
          md="4"
        >
          <b-form-group>
            <g-field
              field="number"
              label-text="originalValue"
              type="number"
              disabled
              :value.sync="selectedItem.originalValue"
            />
          </b-form-group>
        </b-col>

        <!-- tax value  -->
        <b-col
          v-if="selectedItem.isTaxable"
          md="4"
        >
          <b-form-group>
            <g-field
              field="number"
              label-text="taxValue"
              type="number"
              disabled
              :value.sync="selectedItem.taxValue"
            />
          </b-form-group>
        </b-col>

        <!-- is taxable -->
        <b-col
          md="4"
        >
          <label
            style="font-size: 14px; margin-bottom: 7px"
            for="isTaxable"
          >
            {{ $t('isTaxable') }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="selectedItem.isTaxable"
              class="mr-0 mt-50"
              name="isTaxable"
              inline
            />
          </b-form-group>
        </b-col>

        <!-- supplier Id -->
        <b-col
          v-if="
            (pathName === 'expense-transactions-edit' ||
              pathName === 'expense-transactions-new') &&
              selectedItem.isTaxable
          "
          md="4"
        >
          <b-form-group>
            <g-field
              label="arabicName"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="supplier"
              field="select"
              name="supplierId"
              :options="suppliers"
              :value.sync="selectedItem.supplierId"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="
            (pathName === 'expense-transactions-edit' ||
              pathName === 'expense-transactions-new') &&
              selectedItem.isTaxable
          "
          md="4"
        >
          <g-field
            id="supplierInvoiceNumber"
            label-text="supplierInvoiceNumber"
            :value.sync="selectedItem.supplierInvoiceNumber"
            name="scalesCode"
            type="number"
            rules="required"
          />
        </b-col>
        <!-- supplier tax Number -->
        <b-col
          v-if="
            (pathName === 'expense-transactions-edit' ||
              pathName === 'expense-transactions-new') &&
              selectedItem.isTaxable
          "
          md="4"
        >
          <b-form-group>
            <g-field
              id="supplierTaxNumber"
              name="supplierTaxNumber"
              label-text="taxNumber"
              disabled
              :value.sync="ExtendedItemInfo.supplierTaxNumber"
            />
          </b-form-group>
        </b-col>

        <!-- payment method -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"
              name="paymentMethod"
              :options="paymentWays"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- safe payment  -->
        <b-col
          v-if="selectedItem.paymentMethod == 'cash'"
          md="4"
        >
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="safePayment"
              label-text="safeName"
              :options="safes"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.safeId"
            />
          </b-form-group>
        </b-col>

        <!-- other payment  -->
        <b-col
          v-if="selectedItem.paymentMethod == 'other'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentMethodId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="PaymentMethods"
              field="select"
              name="PaymentMethods"
              :options="payments"
              label="arabicName"
              @change="
                (v) => {
                  selectedItem.bankId = v.bankId;
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- bank method  -->
        <b-col
          v-if="selectedItem.paymentMethod == 'other'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.bankId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="bankName"
              field="select"
              name="bank1"
              disabled
              :options="Banks"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="pt-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </g-form>
    <b-modal
      ref="upload-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <!-- operations -->
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"
                @change="handleFileUpload($event)"
              >
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mr-1"
              variant="relief-primary"
              @click="closeModal"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              {{ $t("save") }}
            </b-button>
            <b-button
              variant="secondary"
              data-action-type="delete"
              :disabled="!file"
              @click="
                () => {
                  removeAttachment();
                }
              "
            >
              <feather-icon
                icon="XCircleIcon"
                class="mr-50"
              />
              {{ $t("cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
    <b-row class="mt-1">
      <b-col
        cols="12"
        class="d-flex justify-content-end"
      >
        <b-button
          v-permission="$route.meta.permission"
          class="mr-1"
          type="submit"
          data-action-type="save"
          variant="relief-primary"
          @click="save()"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          {{ $t('save') }}
        </b-button>
        <b-button
          v-permission="$route.meta.permission"
          :disabled="!selectedItem.value"
          variant="outline-primary"
          data-action-type="saveAndPrint"
          @click="save('saveAndPrint')"
        >
          <feather-icon
            icon="PrinterIcon"
            class="mr-50"
          />
          {{ $t('saveAndPrint') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { paymentWays } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      file: '',
      selectedItem: {
        transactionDate: '',
        value: 0,
        paymentMethod: 'cash',
        bankId: null,
        createdBy: 0,
        isDeleted: false,
        incomeId: 0,
        supplierId: null,
        paymentMethodId: 0,
        creationDate: '',
        lastUpdateBy: 0,
        lastUpdateDate: '',
        isTaxable: false,
        originalValue: 0,
        taxValue: 0,
      },
      Banks: [],
      expenses: [],
      incomes: [],
      safes: [],
      suppliers: [],
      paymentMethods: [],
      transactionDate: this.today,
      chequeDate: this.today,
      paymentWays: paymentWays,
      pathName: this.$route.name,

      ExtendedItemInfo: {
        supplierTaxNumber: '',
      },
    };
  },

  watch: {
    'selectedItem.paymentMethod'(newval) {
      if (newval === 'cash') {
        this.selectedItem.bankId = null;
        this.selectedItem.paymentMethodId = null;
        this.bankName = null;
      } else if (newval === 'other') {
        this.selectedItem.safeId = null;
      }
    },

    'selectedItem.value'(value) {
      if (this.selectedItem.isTaxable) {
        this.selectedItem.originalValue = this.getOriginalValue(
          this.selectedItem.value
        );
        this.selectedItem.taxValue = this.getTaxValue(this.selectedItem.value);
      } else {
        this.selectedItem.originalValue = value;
        this.selectedItem.taxValue = 0;
      }
    },

    'selectedItem.supplierId'(supplierId) {
      if (!supplierId) {
        this.ExtendedItemInfo.supplierTaxNumber = '';
      } else {
        this.ExtendedItemInfo.supplierTaxNumber = this.suppliers.find(
          (val) => val.id === supplierId
        ).taxNumber;
      }
    },

    'selectedItem.isTaxable'(isTaxable) {
      if (this.selectedItem.value > 0) {
        if (isTaxable) {
          this.selectedItem.originalValue = this.getOriginalValue(
            this.selectedItem.value
          );
          this.selectedItem.taxValue = this.getTaxValue(
            this.selectedItem.value
          );
        } else {
          this.selectedItem.originalValue = this.selectedItem.value;
          this.selectedItem.taxValue = 0;
        }
      }
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    this.selectedItem.transactionTime = this.getTime();
    this.loadObj();
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    loadObj() {
      this.getSuppliers();
      this.getExpenses();
      this.getIncomes();
      this.getBanks();
    },

    getBanks() {
      this.get({ url: 'Safes' }).then((data) => {
        this.safes = data;
      });
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.payments = data;
        if (data && this.id > 0) {
          this.getData();
        }
      });
    },

    getIncomes() {
      this.get({ url: 'Incomes' }).then((data) => {
        this.incomes = data;
      });
    },

    getExpenses() {
      this.get({ url: 'expenses' }).then((data) => {
        this.expenses = data;
      });
    },

    getSuppliers() {
      this.get({ url: 'suppliers' }).then((data) => {
        this.suppliers = data;
      });
    },

    getData() {
      if (
        this.pathName === 'income-transactions-new' || this.pathName === 'income-transactions-edit'
      ) {
        this.get({
          url: 'IncomeTransactions',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
        });
      } else {
        this.get({
          url: 'ExpensesTransactions',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
        });
      }
    },

    getOriginalValue(totalValue) {
      return (totalValue / 1.15).toFixed(2);
    },

    getTaxValue(totalValue) {
      return (totalValue - totalValue / 1.15).toFixed(2);
    },

    isValidData() {
      // if no payment method selected
      if (!this.selectedItem.paymentMethod) {
        this.doneAlert({
          text: this.$t('shouldSelectPaymentMethod'),
          type: 'warning',
        });
        return false;
      }

      // if cash payment method selected and not select a safe
      if (
        this.selectedItem.paymentMethod === 'cash' && !this.selectedItem.safeId
      ) {
        this.doneAlert({ text: this.$t('shouldSelectSafe'), type: 'warning' });
        return false;
      }

      // if other payment method selected and not select a bank
      if (
        this.selectedItem.paymentMethod === 'other' && !this.selectedItem.paymentMethodId
      ) {
        this.doneAlert({
          text: this.$t('shouldSelectPaymentType'),
          type: 'warning',
        });
        return false;
      }

      // all is well
      return true;
    },

    save(type) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      if (!this.isValidData()) {
        return;
      }

      this.selectedItem.branchId = this.branchId;
      if (
        this.pathName === 'income-transactions-new' || this.pathName === 'income-transactions-edit'
      ) {
        if (this.id > 0) {
          this.update({
            url: 'IncomeTransactions',
            data: this.selectedItem,
            id: this.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            if (type === 'saveAndPrint') this.print(this.selectedItem.id);
            if (type === 'cancel') {
              window.location.reload();
            } else {
              this.$router.push({ name: 'income-transactions' });
            }
          });
        } else {
          this.create({
            url: 'IncomeTransactions',
            data: this.selectedItem,
          }).then((data) => {
            this.doneAlert({
              text: this.$t('savedSuccessfullyWithCode', {
                code: data.code,
              }),
            });
            if (type === 'saveAndPrint') this.print(data.id);
            this.$router.push({ name: 'income-transactions' });
          });
        }
      } else if (
        this.pathName !== 'income-transactions-new' || this.pathName !== 'income-transactions-edit'
      ) {
        if (this.id > 0) {
          this.update({
            url: 'ExpensesTransactions',
            data: this.selectedItem,
            id: this.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            if (type === 'saveAndPrint') this.print(this.selectedItem.id);
            if (type === 'cancel') {
              window.location.reload();
            } else {
              this.$router.push({ name: 'expense-transactions' });
            }
          });
        } else {
          this.create({
            url: 'ExpensesTransactions',
            data: this.selectedItem,
          }).then((data) => {
            this.doneAlert({
              text: this.$t('savedSuccessfullyWithCode', {
                code: data.code,
              }),
            });
            if (type === 'saveAndPrint') this.print(data.id);
            this.$router.push({ name: 'expense-transactions' });
          });
        }
      }
    },

    print(transactionId) {
      const reportName = this.isRight ? 'ExpenseAndIncomeTransaction-ar' : 'ExpenseAndIncomeTransaction-en';
      const printedItem = {
        id: transactionId,
        transactionType:
          this.pathName === 'income-transactions-new' || this.pathName === 'income-transactions-edit'
            ? 0
            : 1,
      };
      this.printReport(reportName, printedItem);
    },

    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.imageBase64Content = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t(
            'Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG, .zip suffix files'
          ),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|xlsx|PNG|zip)$/.test(file.name);
    },
  },
};
</script>

<style></style>
